body {
  margin: 0;
  font-family:"Oslo Sans",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(212, 212, 219);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


* {
  box-sizing: none !important;
}

#root {
  width: 100%;
  height: 100%;
}

header {
  margin-top: 12px;
  margin: 30px;
  padding: 5px;
}

header h1 {
  color:rgb(12, 89, 124);
  font-size: 30px;
}

.container-fluid {
  margin: 0 !important;
}

.container {
  border-radius: 4px;
}

.forslag {
  padding: 10px;
  color: #130e0e;
}

.forslagCol {
  border: 1px dotted #000;
  background-color: rgba(4, 68, 41, 0.493);
  color: rgb(255, 255, 255);
  border-radius: 2px;
}

.forslagCol .info  {
  font-size: 15px !important;
  color: #fafafa;
  
}

.forslagCol:hover {
  background-color: rgba(8, 48, 30, 0.493);
  cursor: pointer;
  
}

.gebyrberegning {
  color: white !important;
  background-color: rgb(255, 255, 255);
  padding-bottom: 15px;
  border-radius: 2px;
}

.gebyrberegning h3 {
  color: rgb(53, 53, 53);
  padding: 5px;
  letter-spacing: 2px;
}

.sum {
  background-color: #e3eaf7 !important;
}

.noHover:hover {
  pointer-events: none !important;
  background-color: white !important;
	opacity:1.0;
	box-shadow:none;
}

.overlay {
  background-color: rgba(255,255,255,0.9);
  padding: 5px;
  border-radius: 4px;
  margin-bottom: 5px;
}

.endring-billig {
  padding-top: 7.5px;
  background-color: #e9f8e3 !important;
  color: rgb(32, 32, 32) !important;
}
.endring-dyr {
  padding-top: 7.5px;
  background-color: #fdd9df !important;
  color: rgb(0, 0, 0) !important;
}

p {
  font-size: 18px !important;
  padding: 5px;
  line-height: 180%;
}





/**
* Font - Family
**/

@font-face{
  font-family:"Oslo Sans";
  font-weight:400;
  font-display:swap;
  src:url(
    https://ukeweb-styleguide-cdn.s3.dualstack.eu-central-1.amazonaws.com/0.99.105/OsloSans-Regular.woff2) format("woff2"),url(
    https://ukeweb-styleguide-cdn.s3.dualstack.eu-central-1.amazonaws.com/0.99.105/OsloSans-Regular.woff) format("woff")
}
@font-face{
  font-family:"Oslo Sans";
  font-style:italic;
  font-weight:400;
  font-display:swap;
  src:url(
    https://ukeweb-styleguide-cdn.s3.dualstack.eu-central-1.amazonaws.com/0.99.105/OsloSans-RegularItalic.woff2) format("woff2"),url(
    https://ukeweb-styleguide-cdn.s3.dualstack.eu-central-1.amazonaws.com/0.99.105/OsloSans-RegularItalic.woff) format("woff")
}
@font-face{
  font-family:"Oslo Sans";
  font-weight:300;
  font-display:swap;
  src:url(
    https://ukeweb-styleguide-cdn.s3.dualstack.eu-central-1.amazonaws.com/0.99.105/OsloSans-Light.woff2) format("woff2"),url(
    https://ukeweb-styleguide-cdn.s3.dualstack.eu-central-1.amazonaws.com/0.99.105/OsloSans-Light.woff) format("woff")
}
@font-face{
  font-family:"Oslo Sans";
  font-style:italic;
  font-weight:300;
  font-display:swap;
  src:url(
    https://ukeweb-styleguide-cdn.s3.dualstack.eu-central-1.amazonaws.com/0.99.105/OsloSans-LightItalic.woff) format("woff")
}
@font-face{
  font-family:"Oslo Sans";
  font-weight:500;
  font-display:swap;
  src:url(
    https://ukeweb-styleguide-cdn.s3.dualstack.eu-central-1.amazonaws.com/0.99.105/OsloSans-Medium.woff2) format("woff2"),url(
      https://ukeweb-styleguide-cdn.s3.dualstack.eu-central-1.amazonaws.com/0.99.105/OsloSans-Medium.woff) format("woff")
}
@font-face{
  font-family:"Oslo Sans";
  font-style:italic;
  font-weight:500;
  font-display:swap;
  src:url(
    https://ukeweb-styleguide-cdn.s3.dualstack.eu-central-1.amazonaws.com/0.99.105/OsloSans-MediumItalic.woff) format("woff")
}
@font-face{
  font-family:"Oslo Sans";
  font-weight:700;
  font-display:swap;
  src:url(
    https://ukeweb-styleguide-cdn.s3.dualstack.eu-central-1.amazonaws.com/0.99.105/OsloSans-Bold.woff2) format("woff2"),url(
      https://ukeweb-styleguide-cdn.s3.dualstack.eu-central-1.amazonaws.com/0.99.105/OsloSans-Bold.woff) format("woff")
}
@font-face{
  font-family:"Oslo Sans";
  font-style:italic;
  font-weight:700;
  font-display:swap;
  src:url(
    https://ukeweb-styleguide-cdn.s3.dualstack.eu-central-1.amazonaws.com/0.99.105/OsloSans-BoldItalic.woff) format("woff")
}
@font-face{
  font-family:"Oslo Icons";
  font-style:normal;
  font-weight:normal;
  font-display:swap;
  src:url(
    https://ukeweb-styleguide-cdn.s3.dualstack.eu-central-1.amazonaws.com/0.99.105/OsloIcons.woff2) format("woff2"),url(
    https://ukeweb-styleguide-cdn.s3.dualstack.eu-central-1.amazonaws.com/0.99.105/OsloIcons.woff) format("woff")
}